import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavService } from 'src/app/layout/side-nav/services/side-nav.service';
import { ManagementRoutingModule } from './management-routing.module';
import { managementSidenavConfig } from './managementSidenav.config';

@NgModule({
  imports: [CommonModule,ManagementRoutingModule],
})
export class ManagementModule {
  constructor(sideNavService: SideNavService) {
    sideNavService.addSubMenuItems('management', managementSidenavConfig);
  }
}
