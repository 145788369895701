import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const cmSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'vehicles',
    label: 'vehicles',
    routerLink: '/vehicles',
    permissions: ['VIEW_VEHICLE'],
  },
  {
    id: 'maintenanceType',
    label: 'maintenanceType', 
    routerLink: '/maintenance-type',
    permissions: ['VIEW_MAINTENANCE_TYPE'],
  },
  {
    id: 'features',
    label: 'features',
    routerLink: '/features',
    permissions: ['VIEW_FEATURE'],
  },
  {
    id: 'files',
    label: 'files',
    routerLink: '/files',
    permissions: ['VIEW_FILE_TYPE'],
  },
  {
    id: 'vehicleFiles',
    label: 'vehicleFiles',
    routerLink: '/vehicle-files',
    permissions: ['VIEW_VEHICLE_FILE'],
  },
  {
    id: 'fine',
    label: 'fine',
    routerLink: '/fine',
    permissions: ['VIEW_FINE'],
  },
  {
    id: 'reminderTypes',
    label: 'reminderTypes',
    routerLink: '/reminderTypes',
    permissions: ['VIEW_REMINDER_TYPE'],
  },
  {
    id: 'reminder',
    label: 'reminder',
    routerLink: '/reminder',
    permissions: ['VIEW_REMINDER'],
  },
  {
    id: 'maintenance',
    label: 'maintenance',
    routerLink: '/maintenance',
    permissions: ['VIEW_MAINTENANCE'],
  },
];
