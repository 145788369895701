import { SidenavItemInterface } from 'src/app/layout/side-nav/interfaces/side-nav-items.interface';

export const managementSidenavConfig: SidenavItemInterface[] = [
  {
    id: 'chatSupport',
    label: 'chatSupport',
    routerLink: '/chat-support',
    permissions: ['VIEW_SUPPORT_CHAT', 'SUPPORT_CHAT_AGENT'],
  },
  {
    id: 'supportEmails',
    label: 'supportEmails',
    routerLink: '/email-support',
    permissions: ['VIEW_SUPPORT_EMAIL'],
  },
  {
    id: 'suggestions',
    label: 'suggestions',
    routerLink: '/suggestions',
    permissions: ['VIEW_SUGGESTIONS'],
  },
  {
    id: 'lostItemsRequests',
    label: 'lostItemsRequests',
    routerLink: '/lost-items-requests',
    permissions: ['VIEW_LOST_ITEM_REQUEST'],
  },
  {
    id: 'vehicleMalfunction',
    label: 'vehicleMalfunction',
    routerLink: '/vehicle-malfunction',
    permissions: ['VIEW_MALFUNCTION_REPORTS'],
  },
  {
    id: 'routeRequests',
    label: 'route_requests',
    routerLink: '/route-requests',
    permissions: ['VIEW_ROUTE_REQUEST'],
  },
];
